import React, { useState, useCallback, useRef, useEffect } from "react";
import QrReader from "react-qr-scanner";
import { userCheckinService } from "../../../Services/userDetailService";
import "./scanner.css";
import { toast } from "react-toastify";
import lottie from "lottie-web";
import Navbar from "../../../Components/Layout/Navbar";
function Scan() {
  const [result, setResult] = useState("");
  const [loader, setloader] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const container = useRef(null);
  const container1 = useRef(null);
  const container2 = useRef(null);
  const scannerRef = useRef(null);

  const handleScan = async (data) => {
    if (data?.text) {
      setloader(true);
      const result = await userCheckinService(data.text);
      setloader(false);
      setResult(result);
      setTimeout(() => {
        setResult("");
      }, [4000]);
    }
  };

  const handleError = (err) => {
    toast(err);
    // console.error(err);
  };

  useEffect(() => {
    const loadAnimationData = async () => {
      const animationDataModule = await import(
        "../../../Assets/Animation - 1709728035204.json"
      );
      lottie.loadAnimation({
        container: container.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationDataModule.default,
      });
    };
    const loadAnimationData1 = async () => {
      const animationDataModule = await import(
        "../../../Assets/Animation - 1709729595357.json"
      );
      lottie.loadAnimation({
        container: container1.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationDataModule.default,
      });
    };
    const loadAnimationData2 = async () => {
      const animationDataModule = await import(
        "../../../Assets/Animation - 1709729843449.json"
      );
      lottie.loadAnimation({
        container: container2.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationDataModule.default,
      });
    };
    if (
      result ===
      "You have already checked in for the Hero Inspire Inclusion Event"
    )
      loadAnimationData();
    else if (
      result === "Thank you for attending the Hero Inspire Inclusion Event"
    ) {
      loadAnimationData2();
      loadAnimationData1();
    }
  }, [result]);

  if (loader) {
    return (
      <div className="main_container" style={{ alignItems: "center" }}>
        <div className="scanner_box" style={{ height: "31vh", color: "white" }}>
          <h3>Extracting user detail. Please wait</h3>
        </div>
      </div>
    );
  }
  const handleFullscreen = () => {
    setFullScreen(true);
    if (scannerRef.current) {
      if (scannerRef.current.requestFullscreen) {
        scannerRef.current.requestFullscreen();
      } else if (scannerRef.current.mozRequestFullScreen) {
        // Firefox
        scannerRef.current.mozRequestFullScreen();
      } else if (scannerRef.current.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        scannerRef.current.webkitRequestFullscreen();
      } else if (scannerRef.current.msRequestFullscreen) {
        // IE/Edge
        scannerRef.current.msRequestFullscreen();
      }
    }

    // Check if fullscreen is enabled
    const isFullscreenEnabled =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement;

    if (!isFullscreenEnabled) {
      setFullScreen(false);
    }
  };
  return (
    <div ref={scannerRef}>
      {!fullScreen && (
        <div
          style={{
            position: "absolute",
            height: "100vh",
            width: "100vw",
            background: "black",
            left: "0",
            top: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button onClick={handleFullscreen}>Full Screen</button>
        </div>
      )}
      {fullScreen && (
        <>
          <Navbar />

          <div
            className="main_container"
            style={{ justifyContent: "center", maxWidth: "65vw" }}
          >
            <div className="scanner_box ">
              {result !== "" ? (
                result ===
                "Thank you for attending the Hero Inspire Inclusion Event" ? (
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      color: "white",
                    }}
                  >
                    <div ref={container2} style={{ height: "30vh" }}></div>
                    <h3>for attending the event</h3>
                    <div ref={container1}></div>
                  </div>
                ) : (
                  <div
                    style={{
                      height: "30vh",
                      textAlign: "center",
                      justifyContent: "center",
                      color: "white",
                    }}
                  >
                    <div ref={container} style={{ height: "100%" }}></div>
                    <h3>
                      You have already checked in <br></br>for the event
                    </h3>
                  </div>
                )
              ) : (
                <div style={{ height: "59vh", textAlign: "center" }}>
                  <h3 className="heading">
                    Place the QR Code
                    <br></br>
                    within this box to scan
                  </h3>
                  <QrReader
                    onError={handleError}
                    onScan={handleScan}
                    style={{
                      margin: "10px",
                      width: "75%",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Scan;
