export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// Authentication
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_FAIL = "REGISTRATION_FAIL";

export const USER_DETAIL = "USER_DETAIL";
export const USER_IMAGE = "USER_IMAGE";
export const GUEST_LIST = "GUEST_LIST";
