// Package Imports
import React from "react";
import { Routes, Route } from "react-router-dom";
import Register from "../Views/Home/Register/Register";
import Thankyou from "../Views/Home/Thankyou/Thankyou";
import Scan from "./../Views/Home/Scanner/Scan";
import Dashboard from "../Views/Dashboard/Dashboard";

// View Imports

const HomeRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Register />} />
      <Route path="/thankyou" element={<Thankyou />} />
      <Route path="/scanner" element={<Scan />} />
      <Route path="/dash" element={<Dashboard />} />
      <Route path="*" element={<Register />} />
    </Routes>
  );
};

export default HomeRoute;
