// Package Imports
import React, { Fragment, useEffect, useRef } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import bg from "./Assets/shapes.png";
// Component Imports

// Route Imports

import HomeRoute from "./Routes/HomeRoute";
import Navbar from "./Components/Layout/Navbar";
import Scanner from "./Views/Home/Scanner/Scanner";

const App = () => {
  return (
    <div>
      <Router>
        <Fragment>
          <div
            style={{
              position: "absolute",
              width: "100vw",
              maxWidth: "500px",
              left: "0",
              top: "0",
            }}
          >
            <img src={bg} alt="logo" style={{ width: "100%" }} />
          </div>
          <Navbar />
          <ToastContainer />

          <HomeRoute />
        </Fragment>
      </Router>
    </div>
  );
};

export default App;
