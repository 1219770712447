import { GUEST_LIST } from "../Actions/Types";

// Defining Initial State
const initialState = {
  guestList: [],
};

// Defining Reducer Function
export const guestState = (state = initialState, action) => {
  // Destructuring Action
  const { type, payload } = action;

  switch (type) {
    // Add user Case
    case GUEST_LIST: {
      return {
        ...state,
        guestList: payload,
      };
    }
    // Default Case
    default: {
      return state;
    }
  }
};
