import React from "react";
import logo from "../../Assets/M3M_Logo.svg";

import "./Navbar.css";
import { useNavigate } from "react-router-dom";
function Navbar() {
  const navigate = useNavigate();
  return (
    <div className="navbar">
      <div>
        <img
          src={logo}
          alt="logo"
          onClick={() => {
            navigate("/");
          }}
          onTouchStart={() => {
            navigate("/");
          }}
          onTouchEnd={() => {
            navigate("/");
          }}
        />
      </div>
    </div>
  );
}

export default Navbar;
