import React, { useEffect, useState } from "react";
import { GuestListService } from "../../Services/GuestListService";
import { toast } from "react-toastify";
import downloadbtn from "../../Assets/download.svg";
function Dashboard() {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    password: "",
  });
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    const handleApiCall = async () => {
      await GuestListService().then((res) => {
        setData(res);
      });
    };

    if (submit) {
      handleApiCall();
    }
    const intervalId = setInterval(async () => {
      if (submit) handleApiCall();
    }, 60000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [submit]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    console.log(formData);
    if (formData.id === "admin" && formData.password === "m3m@123") {
      toast("Login Successful");
      setSubmit(true);
    }
  };

  const downloadCSV = () => {
    const csvData = data.map((row) => ({
      Name: row.name,
      Email: row.email,
      "Company Name": row.companyName,
      Phone: row.phone,
      CheckedIn: row.isCheckedIn ? "Yes" : "No",
    }));

    const csvContent =
      "data:text/csv;charset=utf-8," +
      [
        Object.keys(csvData[0]).join(","),
        ...csvData.map((row) => Object.values(row).join(",")),
      ].join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "guest_list.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {data.length === 0 ? (
        <div
          className="registration_box"
          style={{
            position: "relative",
            marginTop: "10vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <label>Id</label>
          <input
            type="text"
            name="id"
            onChange={handleChange}
            className="input_box"
          />
          <br></br>
          <label>Password</label>
          <input
            type="password"
            name="password"
            onChange={handleChange}
            className="input_box"
          />
          <br></br>
          <button className="btn" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      ) : (
        <div style={{ position: "relative" }}>
          <div
            style={{
              color: "white",
              width: "100%",
              textAlign: "center",
              marginBottom: "30px",
              position: "relative",
            }}
          >
            Total User {data.length}
            <button
              onClick={downloadCSV}
              style={{ height: "30px", position: "absolute", right: "0" }}
            >
              <img
                src={downloadbtn}
                alt="download"
                style={{ height: "100%" }}
              />
            </button>
          </div>

          <div
            style={{
              color: "white",
              display: "flex",
              gap: "auto",
              width: "90vw",
            }}
          >
            <div
              style={{
                width: "25%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                backgroundColor: "black",
              }}
            >
              Name
            </div>
            <div
              style={{
                width: "25%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              Email
            </div>
            <div
              style={{
                width: "25%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              Company Name
            </div>
            <div
              style={{
                width: "25%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              Phone
            </div>
            <div
              style={{
                width: "25%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              CheckedIn
            </div>
          </div>
          <div
            style={{
              height: "50vh",
              width: "90vw",
              overflowY: "auto", // Ensure vertical scrolling is enabled
              overflowX: "auto", // Ensure horizontal scrolling is enabled
            }}
          >
            {data.map((value, index) => (
              <div
                key={index}
                style={{
                  color: "white",
                  display: "flex",
                  gap: "auto",
                  backgroundColor: "black",
                }}
              >
                <div
                  style={{
                    width: "20%",
                    border: "2px solid white",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    backgroundColor: "black",
                  }}
                >
                  {value.name}
                </div>
                <div
                  style={{
                    width: "20%",
                    border: "2px solid white",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {value.email}
                </div>
                <div
                  style={{
                    width: "20%",
                    border: "2px solid white",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {value.companyName}
                </div>
                <div
                  style={{
                    width: "20%",
                    border: "2px solid white",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {value.phone}
                </div>
                <div
                  style={{
                    width: "20%",
                    border: "2px solid white",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {value.isCheckedIn ? "Yes" : "No"}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;
