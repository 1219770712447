import React, { useEffect } from "react";
import "./Thankyou.css";
import { useLocation, useNavigate } from "react-router-dom";
function Thankyou() {
  const location = useLocation();
  const navigate = useNavigate();
  const registrationResult = location.state?.registrationResult || null;

  const handleClick = () => {
    // Create a new anchor element
    const a = document.createElement("a");
    a.href = registrationResult.downloadable_url;
    a.click();
  };

  useEffect(() => {
    if (registrationResult == null) {
      navigate("/");
    }
  }, [registrationResult]);
  return (
    <div className="register_main_container">
      <div className="registeration_box">
        <div style={{ width: "250px" }}>
          <img
            src={`data:image/png;base64,` + registrationResult?.watchable_url}
            alt="qrcode"
            style={{ width: "100%" }}
          />
        </div>
        <div className="heading2">
          Thanks for the event registration. <br></br>This QR code has been sent
          to your registered email id also.
        </div>
        {/* <button className="btn" onClick={handleClick}>
          Download
        </button> */}
      </div>
    </div>
  );
}

export default Thankyou;
