import React, { useState } from "react";
import "./Register.css";
import { registerUser } from "../../../Services/userDetailService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Register() {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    checked: true,
  });

  const onInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      if (name === "phone" && value.length !== 0) {
        const phoneRegex = /^[1-9]\d*$/;
        if (phoneRegex.test(value) === false || value.length > 10) return;
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const isValidEmail = (email) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.name === "Scanner") {
      navigate("/scanner");
      return;
    }

    if (
      formData.name === "" ||
      formData.email === "" ||
      formData.phone === "" ||
      formData?.companyName === ""
    ) {
      toast("All fields are required!");
      return;
    }

    if (!isValidEmail(formData.email)) {
      toast("Invalid email address!");
      return;
    }
    if (formData?.phone.length < 10) {
      toast("Invalid mobile number");
      return;
    }

    setLoader(true);
    const result = await registerUser(formData);
    if (result) {
      setLoader(false);
      navigate("/thankyou", { state: { registrationResult: result } });
    }
  };

  if (loader) {
    return (
      <div
        style={{
          height: "65vh",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "2",
          position: "relative",
        }}
      >
        <h1 className="heading golden">Generating...</h1>
      </div>
    );
  }

  return (
    <div className="main_container">
      <div className="registeration_box">
        <div className="heading">Registration</div>
        <div className="input_box">
          <input
            value={formData?.name}
            name="name"
            onChange={onInputChange}
            placeholder="Enter Your Full Name"
          />
        </div>
        <div className="input_box">
          <input
            value={formData?.phone}
            name="phone"
            onChange={onInputChange}
            placeholder="Enter Your Mobile Number"
          />
        </div>
        <div className="input_box">
          <input
            value={formData?.email}
            name="email"
            onChange={onInputChange}
            placeholder="Enter Your Email ID"
            style={{ textTransform: "inherit" }}
          />
        </div>
        <div className="input_box">
          <input
            value={formData?.companyName}
            name="companyName"
            onChange={onInputChange}
            placeholder="Enter Your Company/Proprietor Name"
            style={{ textTransform: "inherit" }}
          />
        </div>

        <div
          style={{
            width: "290px",
            marginTop: "5px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* <div style={{ width: "auto" }}>
            <input
              type="checkbox"
              name="checked"
              value={formData?.checked}
              checked={formData?.checked}
              onChange={onInputChange}
              style={{ width: "20px", height: "20px" }}
            />
            <label for="vehicle1"> Will you be attending the event?</label>
          </div> */}
        </div>

        <div>
          <button onClick={handleSubmit} className="btn">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default Register;
