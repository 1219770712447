import axios from "axios";
import { BASE_API } from "../GlobalVariable";

// #region UserListService
export const GuestListService = async () => {
  try {
    // Url
    const url = `${BASE_API}/hero/get-users`;
    // Access Token

    // Config
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const result = await axios.get(url, config);

    return Promise.resolve(result?.data?.result);
  } catch (err) {
    return Promise.reject(err);
  }
};
// #endregion
